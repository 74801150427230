// 详情from
export const formInfoConfig = () => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      minWidth: '150px',
      span: 24
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      minWidth: '150px',
      span: 12
    },
    {
      label: '收款人名称',
      prop: 'account',
      minWidth: '150px',
      span: 12
    },
    {
      label: '收款人开户银行',
      prop: 'bankName',
      minWidth: '150px',
      span: 12
    },
    {
      label: '收款人银行账号',
      prop: 'accountNo',
      minWidth: '150px',
      span: 12
    }
  ]
}
// 表格数据
export const tableConfig = () => {
  return [
    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '合同编号',
      prop: 'contractCode'
    },
    {
      label: '合同名称',
      prop: 'contractName'
    }
  ]
}
