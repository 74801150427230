// 详情弹出框
<template>
  <base-dialog :visible.sync="visibleState" :showFooter="false" title="详情">
    <div>
      <!-- 头部信息 -->
      <formInfo
        :count="count"
        :componentList="formInfoConfig"
        :model="detailInfo"
      />
      <!-- 列表数据 -->
      <base-table
        :columns="detailColumus"
        :dataSource.sync="detailData"
        :showPage="false"
        :isCaculateHeight="false"
      >
        <template slot="index" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
      </base-table>
    </div>
    <template slot="footer">
      <base-button label="关闭" @click="visibleState = false" type="default" />
    </template>
  </base-dialog>
</template>
<script>
import { formInfoConfig, tableConfig } from '../utils/detalis-config'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import FormInfo from '@/components/packages/form-info/form-info.vue'
import { riskAPi } from '@/api/riskApi'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
export default {
  components: { BaseDialog, FormInfo, BaseButton, BaseTable },
  props: {
    visible: Boolean,
    finResultId: String,
    businessId: String,
    detailInfo: Object
  },
  data () {
    return {
      detailData: [],
      count: 0
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.detail()
      }
    }
  },
  computed: {
    formInfoConfig () {
      return formInfoConfig(this)
    },
    detailColumus () {
      return tableConfig(this)
    },
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    api () {
      return riskAPi
    }
  },
  methods: {
    // 详情表格数据
    detail () {
      this.detailData = []
      this.api
        .contract({
          businessId: this.businessId,
          finResultId: this.finResultId
        })
        .then((res) => {
          if (res.success) {
            this.detailData = res.data
            this.count++
          }
        })
    }
  }
}
</script>
